<template>
  <div>
    <v-menu
      max-width="300"
      class="elevation-0 primary rounded-pill"
      open-on-hover
      color="primary"
      rounded="pill"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-card
          class="vindex"
          color="transparent"
          width="310"
          height="55"
          elevation="0"
          v-bind="attrs"
          v-on="on"
          style=""
        >
          <div class="d-flex align-center">
            <v-avatar class="elevation-5" color="primary" size="60">
              <v-img max-width="55" max-height="55" :src="image"></v-img>
            </v-avatar>
          </div>
        </v-card>
      </template>

      <v-card max-width="300" color="primary" height="60">
        <v-card-actions class="ma-0 pa-0">
          <v-avatar class="elevation-5 vindexe" color="primary" size="60">
            <v-img max-width="55" max-height="55" :src="image"></v-img>
          </v-avatar>
          <div class="d-inline ml-3" v-for="item in links" :key="item">
            <v-btn
              target="_blank"
              :href="item.link"
              width="60"
              height="60"
              icon
              color="secondary"
              ><v-icon>{{ item.icon }}</v-icon>
            </v-btn>
          </div>
          <div class="d-flex ml-5 flex-column ">
            <span style="font-size: 12px" class="mb-0">{{name}}</span>

            <span style="font-size: 10px" class="mb-0 grey--text">{{role}}</span>
          </div>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
//https://cdn.discordapp.com/avatars/977364035941060678/09fc75480a70d001d848302df0c330de.webp?size=128
export default {
  data: () => {
    return {};
  },

  props: ["image", "links", "name", "role"],
};
</script>

<style>
.vindex {
}
.vindexe {
}
</style>