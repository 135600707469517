<template>
  <div class="fullscreened">
    <div class="fullscreened">
      <SunsetSvgVue class="svg" />

      <v-card class="mx-auto" elevation="0" color="transparent">
        <v-card-text class="text-center">
          <h3 style="margin-top: 10%" class="text-h3 secondary--text">
            VAMPORIA
          </h3>
          <h2 class="text-h1 secondary--text">PLAY NOW</h2>
          <v-btn to="/servers" class="" rounded="100%" outlined color="accent"
            >VISIT SERVERS</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            target="_blank"
            class="mt-5"
            href="https://discord.gg/ChT5bJasg4"
            rounded="100%"
            color="accent"
            >Join discord</v-btn
          >
        </v-card-text>
      </v-card>
      <v-container grid-list-xs>
        <team-hover
          :links="[{ icon: 'mdi-twitter', link: 'https://twitter.com/prodigycal?s=21&t=BJcg1IZJyKKsLG03BZZiCg' }]"
          name="Sir Tonny"
          role="Owner"
          image="https://cdn.discordapp.com/avatars/977364035941060678/09fc75480a70d001d848302df0c330de.webp?size=128"
          class="mt-6"
        />
        <team-hover
          :links="[{ icon: 'mdi-twitter', link: 'https://twitter.com/nolzvfx' }, {icon: 'mdi-youtube', link: 'https://www.youtube.com/channel/UCXioU9bmsgRZFPtVzlFTrmQ'}]"
          name="Nolz"
          role="Staff Member"
          image="https://cdn.discordapp.com/avatars/285001209507741696/04a298a618b49a548eedd82effe1b654.webp?size=128"
          class="mt-3"
        />
        <team-hover
          :links="[{ icon: 'mdi-twitter', link: 'https://twitter.com/prodigycal?s=21&t=BJcg1IZJyKKsLG03BZZiCg' }]"
          name="Why"
          role="Staff member"
          image="https://cdn.discordapp.com/avatars/371327713803239455/a_4bb979fd5a8b87101471835e63c78b4a.webp?size=128"
          class="mt-3"
        />
        <team-hover
          :links="[{ icon: 'mdi-twitter', link: 'https://twitter.com/Lyfer_Shmifer' }, {icon: 'mdi-youtube', link: 'https://www.youtube.com/channel/UCe_977G9KpmwTgANcLqBSZg'}]"
          name="Lyferon"
          role="Community Manager"
          image="https://thumbs2.imgbox.com/9c/b6/w1n37YS1_t.png"
          class="mt-3"
        />
      </v-container>
    </div>

    <v-card class="mx-auto" color="transparent" elevation="0" max-width="300">
      <v-card-actions justify-center class="text-center justify-center">
        <v-btn href="https://twitter.com/vamporiaservers?s=21&t=nAzdns45nUFBQ0olXX61ow" target="_target" color="accent" icon><v-icon>mdi-twitter</v-icon></v-btn>
        
      </v-card-actions>
      <v-card-text class="text-center">
        Vamporia.net
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src

import SunsetSvgVue from "../components/SunsetSvg.vue";
import TeamHover from "../components/TeamHover.vue";
export default {
  name: "Home",
  components: {
    SunsetSvgVue,
    TeamHover,
  },

  data() {
    return {
      windowsheight: window.innerHeight,
      windowswidth: window.innerWidth,
      vip: true,
      expand: false,
    };
  },
  mounted() {
    window.onresize(() => {
      this.windowsheight = window.innerHeight;
      this.windowswidth = window.innerWidth;
    });
  },
};
</script>

<style>
.svg {
  position: absolute;
  top: 0%;
}
body {
  width: 100%;
  height: 100vh;
  margin: 0px;
  background: #0e0a0d;
}
.fullscreened {
  height: 100%;
  width: 100%;
}

.outlinedcard.v-sheet.v-card {
  border-width: 1px;
  border-color: red;
  border-style: none;
  background: linear-gradient(#ed1c23, #00000000);
}
</style>
