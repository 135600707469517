<template>
  <v-app  style="background: #0e0a0d;">
    <v-app-bar

      app
      :color="supportsBlur()"
      class="blur-content m-0"
      elevation="0"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="Vamporia logo"
          class="shrink mr-2"
          contain
          src="@/assets/RUST-SERVER-COVER-Recovered.png"
          transition="scale-transition"
          width="70"
        />

      </div>

      <v-btn
        to="/"
        class="ml-8"
        text
        :ripple="false"
        height="113%"
        tile
      >
        <span class="mr-2">Home</span>
      </v-btn>
      <v-btn
        to="Servers"
        class="ml-0"
        text
        :ripple="false"
        height="113%"
        tile
      >
        <span class="mr-2">Servers</span>
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn
        href="https://discord.gg/ChT5bJasg4"
        target="_blank"
        text
        icon
      >

        <v-icon>mdi-discord</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main class="pt-2 pt-md-0">
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
  methods: {
    supportsBlur() {
      if (CSS.supports("backdrop-filter", "blur(10px)")) {
        return "rgba(255,255,255,0.02)";
      } else {
        return "background";
      }
    },
  }
};
</script>

<style lang="scss">

  @font-face {

  font-family: "CyanRegular";
  src: url("~@/assets/fonts/Cyan_Regular.ttf");
}


.v-dialog { transition: none }
$font-family: "CyanRegular";
$heading-font-family: "CyanRegular";
$body-font-family: "CyanRegular";

.blur-content {
  backdrop-filter: blur(10px);
}
body {
  margin:0;
}
main {
  padding: 0;
}

</style>
